import ApiService from "@/core/services/ApiService";
import { Mutation, Module, VuexModule, Action } from "vuex-module-decorators";
import { common } from '@/store/stateless/common'
import { useUser } from '@/store/composable/User'
import { amend_invoices, amend_invoices_without_vat, allDocuments } from '@/store/modules/car/Customer'

interface keyVariable {
    [key: string] : string | number | undefined
}

const { role_id } = useUser()
@Module
export default class Listing extends VuexModule {
    listing = []
    leadsClick = false

    @Mutation
    ['SET_LISTING'](payload) {
        this.listing = payload
    }

    @Mutation
    ['LeadClicks'](payload) {
        this.leadsClick = payload
    }

    get leadsAndTasks() {
        return (data, insurance_type) => {
                return data.map(x => {
                    let agents = []

                    const firstAgent = x.customer_agents[0]
                    const customer_agents:any = [...x.customer_agents]
                    
                    let agent = firstAgent.agent_name
                    const agentNames = x.customer_agents.map(x => x.agent_name)
                    if(customer_agents.length > 1) agents = customer_agents.filter(x => x.agent_id !== firstAgent.agent_id)
                    if(agents.length > 0) agent = `${firstAgent.agent_name} +`
                    const agentName = new Set(agentNames)
                    const agentNameToString = Array.from(agentName).join(',');

                    return {
                        customer_id: x.id,
                        main_customer_id: x.main_customer_id,
                        name: x.name,
                        email: x.email,
                        phone_number: x.phone_number,
                        complete_phone_number: x.complete_phone_number,
                        customer_status_id: x.customer_status_id,
                        customer_status_text: x.customer_status_text,
                        is_renewal: '-',
                        customer_open_tasks_count: x.customer_open_tasks_count,
                        task_due_date: x.task_due_date,
                        agent,
                        agents,
                        agentNameToString,
                        time_lines: timelines(x),
                        disabled_transfer: insurance_type < 0
                    }
                })
        }
    }

    get PolicyData() {
        return (data, route_name, isChild = false) => {
            if(route_name === 'policy-assigned') return assigned(data, 2, isChild)
            else if(route_name === 'policy-completed') return assigned(data, 4, isChild)
            else if(route_name === 'policy-cancellation-request') return assigned(data, 5, isChild)
            else if(route_name === 'policy-refund-closed') return assigned(data, 10, isChild)
            else if(route_name === 'policy-cancellation-approval') return assigned(data, 6, isChild)
            else if(route_name === 'policy-cancelled') return assigned(data, [7, 11], isChild)
            else return assigned(data, 8, isChild)
         }
    }

    get reportsExpiredLeads() {
        return (data, report) => {
                return data.map((x, index) => {
                    return {
                        sr_id: ((report.current_page - 1) * report.per_page)  + (index+ 1),
                        lead_status_text: x.lead_status_text,
                        created_at: x.created_at,
                        deleted_at: x.deleted_at_format, // Expired On
                        el_customer_name: x.el_customer_name,
                        el_customer_email: x.el_customer_email,
                        el_customer_phone_number: x.el_customer_phone_number,
                        el_agent_name: x.el_agent_name,
                        el_lead_source: x.el_lead_source,
                        el_utm_source  : x.el_utm_source    ,
                        el_utm_medium: x.el_utm_medium,
                        el_utm_campaign: x.el_utm_campaign,
                        el_utm_content: x.el_utm_content,
                        el_other_source: x.el_other_source,
                        el_other_utm_source: x.el_other_utm_source,
                        el_other_utm_medium: x.el_other_utm_medium,
                        el_other_utm_campaign: x.el_other_utm_campaign,
                        el_other_utm_content: x.el_other_utm_content,
                        el_lead_details: x.el_lead_details,
                    }
                })
        }
    }

    get reportsLeadBySource() {
        return (data, report) => {
                return data.map((x, index) => {
                    return {
                        sr_id: ((report.current_page - 1) * report.per_page)  + (index+ 1),
                        lead_status_text: x.lead_status_text,
                        created_at: x.created_at,
                        updated_at: x.updated_at_format,
                        el_customer_name: x.el_customer_name,
                        el_customer_email: x.el_customer_email,
                        el_customer_phone_number: x.el_customer_phone_number,
                        el_agent_name: x.el_agent_name,
                        el_lead_source: x.el_lead_source,
                        el_utm_source  : x.el_utm_source    ,
                        el_utm_medium: x.el_utm_medium,
                        el_utm_campaign: x.el_utm_campaign,
                        el_utm_content: x.el_utm_content,
                        el_other_source: x.el_other_source,
                        el_other_utm_source: x.el_other_utm_source,
                        el_other_utm_medium: x.el_other_utm_medium,
                        el_other_utm_campaign: x.el_other_utm_campaign,
                        el_other_utm_content: x.el_other_utm_content,
                        el_lead_details: x.el_lead_details,
                        el_policy_price: common.formatCurrency(x.el_policy_price)
                    }
                })
        }
    }

    get reportsDashboardDetails() {
        return (data, report) => {
                return data.map((x, index) => {
                    return {
                        sr_id: ((report.current_page - 1) * report.per_page)  + (index+ 1),
                        lead_status_text: x.lead_status_text,
                        created_at: x.created_at,
                        updated_at: x.updated_at_format,
                        el_customer_name: x.el_customer_name,
                        el_customer_email: x.el_customer_email,
                        el_customer_phone_number: x.el_customer_phone_number,
                        el_agent_name: x.el_agent_name,
                        el_lead_source: x.el_lead_source,
                        el_utm_source  : x.el_utm_source    ,
                        el_utm_medium: x.el_utm_medium,
                        el_utm_campaign: x.el_utm_campaign,
                        el_utm_content: x.el_utm_content,
                        el_other_source: x.el_other_source,
                        el_other_utm_source: x.el_other_utm_source,
                        el_other_utm_medium: x.el_other_utm_medium,
                        el_other_utm_campaign: x.el_other_utm_campaign,
                        el_other_utm_content: x.el_other_utm_content,
                        el_lead_details: x.el_lead_details,
                        el_policy_price: common.formatCurrency(x.el_policy_price)
                    }
                })
        }
    }

    get reportsAccountMaster() {
        return (data, report) => {
                return data.map((x, index) => {
                    return {
                        sr_id: ((report.current_page - 1) * report.per_page)  + (index+ 1),
                        quote_ref_no: x.quote_ref_no,
                        approved_date: x.approved_date,
                        payment_type_text: x.payment_type_text,
                        bank_transaction_code: x.bank_transaction_code,
                        payment_date: x.payment_date,
                        el_customer_name: x.el_customer_name,
                        el_policy_created_on: x.el_policy_created_on,
                        el_policy_completed_on: x.el_policy_completed_on,
                        el_policy_status_text  : x.el_policy_status_text    ,
                        el_policy_start_date: x.el_policy_start_date,
                        el_policy_end_date: x.el_policy_end_date,
                        el_policy_insurance_policy_number: x.el_policy_insurance_policy_number,
                        el_provider_name: x.el_provider_name,
                        insurance_plan_name: x.insurance_plan_name,
                        insurance_cover_for: x.insurance_cover_for,
                        total_price_vat: common.formatCurrency(x.total_price_vat),
                        vat: common.formatCurrency(x.vat),
                        el_lead_details: x.el_lead_details,
                        el_lead_source: x.el_lead_source,
                        el_agent_name: x.el_agent_name,
                    }
                })
        }
    }

    get reportsAdminReport() {
        return (data) => {
                return data.map((x, index) => {
                    return {
                        sr_id: index + 1,
                        y_month_format: x.y_month_format,
                        el_total_sales: x.el_total_sales,
                        el_total_leads: x.el_total_leads,
                        el_total_closing_ratio: x.el_total_closing_ratio,
                        el_total_orient: x.el_total_orient,
                        el_total_isa: x.el_total_isa,
                        el_total_revenue: x.el_total_revenue,
                        el_new_sales  : x.el_new_sales    ,
                        el_new_leads: x.el_new_leads,
                        el_new_closing_ratio: x.el_new_closing_ratio,
                        el_new_orient: x.el_new_orient,
                        el_new_isa: x.el_new_isa,
                        el_new_revenue: x.el_new_revenue,
                        el_wip_sales: x.el_wip_sales,
                        el_wip_orient: x.el_wip_orient,
                        el_wip_isa: x.el_wip_isa,
                        el_wip_revenue: x.el_wip_revenue,
                        el_lost_leads: x.el_lost_leads,
                        el_expired_leads: x.el_expired_leads,
                        el_untouched_customers: x.el_untouched_customers,
                        el_untouched_new_leads: x.el_untouched_new_leads,
                    }
                })
        }
    }

    get reportsCustomersWithoutLead() {
        return (data) => {
                return data.map((x, index) => {
                    return {
                        main_customer_id: x.main_customer_id,
                        name: x.name,
                        email: x.email,
                        el_customer_complete_phone_number: x.el_customer_complete_phone_number,
                        created_at: x.created_at,
                    }
                })
        }
    }

    get reportsProductionReports() {
        return (data) => {
                return data.map((x, index) => {
                    return {
                        sr_id: index + 1,
                        created_at: x.created_date,
                        start_date: x.requested_start_date,
                        end_date: x.requested_end_date,
                        start_end_date:`${x?.requested_start_date} <br /> ${x?.requested_end_date}`,
                        requested_by: x.user.name,
                        misk_type_text: x.misk_type_text,
                        url: x.url,
                    }
                })
        }
    }

}

function timelines(customer) {
    return {
        current_visited_date: customer.current_visited_date,
        last_visited_date: customer.previous_visited_date,
        created_at: customer.created_at
    }
}

function assigned (data, pStatus, isChild = false) {
    return data.map((x:any) => {
        let startDate = '-'
        if(x?.travel_leads.start_date) startDate = common.setDateOnly(x?.travel_leads.start_date)
        let endDate = '-'
        if(x?.travel_leads.end_date) endDate = common.setDateOnly(x?.travel_leads.end_date)
        
    let online_link = ''
    if(x.transaction_type === 1) online_link = x.invoice.payment_link
    if(x.transaction_type === 5) online_link = x.invoice?.quote_ref_no
    return {
        show_action: true,
        sid: x.sid,
        hasChildren: x.hasChildren,
        invoice_id: x?.invoice?.id,
        is_amend: false,
        quote_ref_no: x?.invoice.quote_ref_no,
        status_text: x.status_text,
        status: x.status,
        email: `${x.invoice.email}`,
        customer_name: x.invoice.name,
        price_vat: `${common.formatCurrency(x?.invoice.total_price_vat)} <br />${common.formatCurrency(x.invoice.vat)}`,
        price: common.formatCurrency(x?.invoice.total_price_vat),
        price_vat_inline: `${common.formatCurrency(x?.invoice.sale_price)} (${common.formatCurrency(x.invoice.vat)})`,
        price_inline: common.formatCurrency(x?.invoice.sale_price),
        amend_invoices: [],
        amend_invoices_without_vat: [],
        // discrepancy_amount: common.formatCurrency(x?.car_lead_invoice?.discrepancy_amount ? x.car_lead_invoice.discrepancy_amount : 0),
        transaction_type_text: `${x?.invoice.payment_type_text ?? '-'} <br /> ${x?.invoice.bank_transaction_code ?? '-'}`,
        destination_country: x?.destination_country,
        policy_type: `${x?.invoice?.insurance_cover_for} <br /> ${x?.invoice?.insurance_coverage_name}`,
        insurance_cover_for: x?.invoice?.insurance_cover_for,
        insurance_coverage_name: x?.invoice?.insurance_coverage_name,
        policy_name: x?.invoice?.insurance_plan_name ? x.invoice.insurance_plan_name : '<span class="text-danger">-</span>',
        created_on: `${x.created_at ? x.created_at : '-'} <br /> ${x.issue_date ? x.issue_date : '-'}`,
        policy_start_date: startDate,
        policy_end_date: endDate,
        cpb: x?.get_provider_detail ? x?.get_provider_detail.provider_description : '-',
        policy_start_end_date: `${startDate}<br />${endDate}`,
        upsa: `${x.policy_underwriter ? x.policy_underwriter.name : '<span class="text-danger"> Not assigned</span>'}<br />${ x.policy_sales_agent ? x.policy_sales_agent.name : '-' }`,
        ibco: `${x?.invoice?.created_by_agent ? x.invoice.created_by_agent.name : '-'}<br />${ x.invoice ? x.invoice.created_at : '-' }`,
        car_value: common.formatCurrency(x?.invoice ? x?.invoice?.insurance_plan_price : 0),
        insurance_payment_text: x?.invoice.payment_type_text ? x?.invoice.payment_type_text : '-',
        policy_document: x?.policy_document ? x.policy_document.file_path : '',
        policy_documents: allDocuments(x?.invoice, x?.invoice.quote_ref_no),
        cancellation_documents: false,
        online_link,
        policy_id: x.id,
        ...showActions(x, x.status)
    }
})
}

function showActions(data, status) {
    let show_view_policy = false
    let show_view_all_documents = false
    let show_view_cancellationDocuments = false
    let show_transfer_sale = false

    show_view_policy = data.policy_document ? true : false
    show_view_all_documents = data.car_lead_documents && data.car_lead_documents.length > 0 ? true : false
    show_view_cancellationDocuments = data.status === 7 ? true : false

    if([2, 4].includes(status)) {
        if([1, 9, 14].includes(role_id) && data.is_active === 1) show_transfer_sale = true
    }

    return {
        show_view_policy,
        show_view_all_documents,
        show_view_cancellationDocuments,
        show_transfer_sale
    }
}
